import './styles.scss';
import React, { useState, useMemo } from 'react';
import CustomWelcomeMessageForm from 'modules/website/pages/edit/components/customWelcomeMessageForm';
import BabyFundsForm from 'modules/website/pages/edit/components/babyFundsForm';
import PhotosForm from 'modules/website/pages/edit/components/photosForm';
import AddEventForm from 'modules/website/pages/edit/components/addEventForm';
import UpdateForm from 'modules/website/pages/edit/components/updateForm';
import Collapse from 'shared/components/collapse';
import EditThemesForm from 'modules/website/pages/edit/components/editThemesForm';
import UpdateAccountInfoForm from 'modules/website/pages/edit/components/updateAccountInfoForm';
import { useSelector } from 'react-redux';
import { getWebsite, getWebsiteSettings } from 'shared/infra/redux/global/global';
import AddGames from 'modules/website/pages/edit/components/addGames';
import { isChecklistTaskCompleted, showFundContributions } from 'shared/utils/helper';
import { useSearchParams } from 'react-router-dom';
import EditUrlForm from 'modules/website/pages/edit/components/editUrlForm';
import ShareBabyForm from 'modules/website/pages/edit/components/shareVillieForm';


interface props {
    // data: any
    editType: 'onboarding' | 'design' | 'content' | 'account';
    children?: any;
    styles?: any;
    // setData: any
}

const MultiSectionEditor: React.FC<props> = ({ editType }) => {
    const data: any = useSelector(getWebsite);
   
    const [closingCollapse, setClosingCollapse] = useState(false);
    const checklist = useSelector(getWebsiteSettings)?.checklist;

    const [searchParams] = useSearchParams();
    // const isContentPage = () => editType === "content"
    // const isAccountInfoPage = () => editType === 'account';
    const tab = new URLSearchParams(window.location.search).get('tab');

    const getChecklistItems = useMemo(() => {
        const checklistItems = [
            {
                id: '35ab3',
                header: (
                    <div className="text-and-diamond">
                       {isChecklistTaskCompleted(checklist, 'Add a Welcome Message') 
                       ? <img src="/assets/checklist-check.svg" alt='checkIcon'/> 
                       : <img className="diamondSize" src="/assets/newIcons/3-diamond.svg" alt='diamond'/>}
                        <span>Welcome Message</span>
                    </div>
                ),
                child: (
                    <CustomWelcomeMessageForm
                        setClosingCollapse={setClosingCollapse}
                    />
                ),
                description:
                    'Edit your message so your Villie can celebrate with you..',
            },
            {
                id: '2ff0b',
                header: (
                    <div className="text-and-diamond">
                       {isChecklistTaskCompleted(checklist, 'Add a Baby Registry') 
                       ? <img src="/assets/checklist-check.svg" alt='checkIcon'/> 
                       : <img className="diamondSize" src="/assets/newIcons/4-diamond.svg" alt='diamond'/>}
                        <span>{showFundContributions(data) && "Funds + "}Registries</span>
                    </div>
                ),
                child: <BabyFundsForm setClosingCollapse={setClosingCollapse} />,
                description: 'You can edit how your Villie supports you!',
            },
            {
                id: '1fb9b',
                header: (
                    <div className="text-and-diamond">
                        {isChecklistTaskCompleted(checklist, 'Post Your First Baby Update') 
                       ? <img src="/assets/checklist-check.svg" alt='checkIcon'/> 
                       : <img className="diamondSize" src="/assets/newIcons/2-diamond.svg" alt='diamond'/>}
                        <span>Updates</span>
                    </div>
                ),
                child: (
                    <UpdateForm
                        handleFormClosing={(arg: boolean) =>
                            setClosingCollapse(arg)
                        }
                        website={data}
                    />
                ),
                description:
                    'Keep your villagers connected by sharing updates throughout your and after your baby arrives.',
            },
            {
                id: 'q548f',
                header: (
                    <div className="text-and-diamond">
                       {isChecklistTaskCompleted(checklist, "Update URL") 
                       ? <img src="/assets/checklist-check.svg" alt='checkIcon'/> 
                       : <img className="diamondSize" src="/assets/newIcons/1-diamond.svg" alt='diamond'/>}
                        <span>Choose a URL</span>
                    </div>
                ),
                child: <EditUrlForm data={data}/>,
                description: 'Let’s customize your Villie url',
            },
            {
                id: 'b348d',
                header: 'Photos',
                child: <PhotosForm />,
                description: 'Let’s add more photos!',
            },
            {
                id: '00dc7',
                header: `Games`,
                child: <AddGames setClosingCollapse={setClosingCollapse} />,
                description:
                    'Adding games to your Villie will engage your villagers like never before!',
            },
            {
                id: '8a154',
                header: 'Events',
                child: (
                    <AddEventForm
                        handleFormClosing={(arg: boolean) =>
                            setClosingCollapse(arg)
                        }
                        website={data}
                    />
                ),
                description:
                    'Let’s add any upcoming events for you and your Villie',
            },
            {
                id: 'jk780',
                header: (
                    <div className="text-and-diamond">
                       {isChecklistTaskCompleted(checklist, "Share Your Baby Page") 
                       ? <img src="/assets/checklist-check.svg" alt='checkIcon'/> 
                       : <img className="diamondSize" src="/assets/newIcons/5-diamond.svg" alt='diamond'/>}
                        <span>Share your Villie</span>
                    </div>
                ),
                child: <ShareBabyForm data={data}/>,
                description: 'Share your Villie to get more support!',
            },
        ];
                            
                     
        // Check for the 'accountFlow' parameter
        if (searchParams.has('accountFlow') && searchParams.get('accountFlow') === 'grg') {
            // Find the "Games" item and bring it to the front
            const gamesItemIndex = checklistItems.findIndex(item => item.id === '00dc7');
            if (gamesItemIndex > -1) {
                const [gamesItem] = checklistItems.splice(gamesItemIndex, 1); // Remove the "Games" item
                checklistItems.unshift(gamesItem); // Add "Games" item to the beginning of the array
            }
        }
    
        return checklistItems;
    }, [checklist, searchParams]);
    
    const isDefaultOpen = () => {
        if(searchParams.has('accountFlow') && searchParams.get('accountFlow') === 'grg'){
            return true
        }
        else if (editType === 'account'){
            return true
        }
        else if (tab === 'theme'){
            return true
        }
        else if (tab === 'registry'){
            return true
        }
        else if (tab && ['genderRevealGame', 'guessWhoGame'].includes(tab)){
            return true
        }

        return false
    }

    const getDefaultOpen = () => {
        if(searchParams.has('accountFlow') && searchParams.get('accountFlow') === 'grg'){
            return '00dc7'
        }
        else if (editType === 'account'){
            return '9T56f'
        }
        else if (tab === 'theme'){
            return '8fe2a'
        }
        else if (tab === 'registry'){
            return '2ff0b'
        }
        else if (tab && ['genderRevealGame', 'guessWhoGame'].includes(tab)){
            return '00dc7'
        }

        return null
    }
    
    return (
        <>
            <Collapse
                defaultActivePanel={
                    isDefaultOpen()?  getDefaultOpen() : null
                }
                closingCollapse={closingCollapse}
                setClosingCollapse={setClosingCollapse}
                items={
                    ['onboarding', 'content'].includes(editType)
                        ? getChecklistItems
                        : editType === 'design'
                        ? [
                              {
                                  id: '8fe2a',
                                  header: 'Theme',
                                  child: <EditThemesForm setClosingCollapse={setClosingCollapse} />,
                                  description:
                                      'Your Villie is customizable to show your style! Edit themes and text so Villie-gers can celebrate with you.',
                              },
                              { id: '5156f', header: 'Fonts + Colors', comingSoon: true },
                          ]
                        : [
                              {
                                  id: '9T56f',
                                  header: 'Edit Info',
                                  hideExpandIcon: true,
                                  dontClose: true,
                                  child: <UpdateAccountInfoForm />,
                                  description:
                                      'Your Villie is customizable to show your style! Edit themes and text so Villie-gers can celebrate with you.',
                              },
                          ]
                }
            />
        </>
    );
};

export default MultiSectionEditor;
                           
                        
                               
           
                           
          
                           
                       
              
                           
                      
    
