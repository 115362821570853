import React, { useEffect, useState } from 'react';
import Modal from 'shared/components/modal';
import { Row, Col, Table, Tag } from 'antd';

interface props {
    show: boolean;
    close: any;
    data: any;
}

const columns = [
    {
        title: 'First Name',
        dataIndex: 'donor_first_name',
        key: 'donor_first_name',
    },
    {
        title: 'Last Name',
        dataIndex: 'donor_last_name',
        key: 'donor_last_name',
    },
    {
        title: 'Donation Amount($)',
        dataIndex: 'donation_amount',
        key: 'donation_amount',
    },
    {
        title: '',
        key: '',
        width: "20px",
        render: (record: any) => (
          <>
            {!!record?.is_grg_voter && (
                <Tag color={'purple'}>
                    GRG Voter
                </Tag>
            )}
            {!!record?.is_gift_card && (
                <Tag color={'gold'}>
                    Gift Card
                </Tag>
            )}
            {!!record?.is_donation && (
                <Tag color={'blue'}>
                    Donation
                </Tag>
            )}
          </>
        ),
    },
];

const validFundTypes = [
    "SquareBaby Baby Food",
    "Believe Diapers",
    "Zomee Breastfeeding Supplies",
    "Wellness"
];

const ViewDonersModal: React.FC<props> = ({ show, close, data }) => {
    const [donersList, setDonersList] = useState<any>([]);

    console.log("I AM DATA", data);

    useEffect(() => {
        const donersList: any = [];

        // Process game voters
        if (data.game && data.game.length > 0) {
            data.game.forEach((gameItem: any) => {
                if (gameItem.voters && gameItem.voters.length > 0) {
                    gameItem.voters.forEach((voter: any) => {
                        if (voter.amount_paid !== null) {
                            donersList.push({
                                donor_first_name: voter.voter_first_name,
                                donor_last_name: voter.voter_last_name,
                                donation_amount: voter.amount_paid,
                                is_grg_voter: true
                            });
                        }
                    });
                }
            });
        }

        // Process donations and add flags
        const processedDonations = data?.donations?.map((donation: any) => ({
            ...donation,
            is_gift_card: validFundTypes.includes(donation.fund_type),
            is_donation: !validFundTypes.includes(donation.fund_type)
        })) || [];

        setDonersList([...processedDonations, ...donersList]);
    }, [data]);

    return (
        <>
            <Modal show={show} close={close} head={false} title="Doners">
                <Row className='addMediaLinkModalContainer'>
                    <Col span={24}>
                        <Table columns={columns} dataSource={donersList} pagination={false} />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default ViewDonersModal;
