import "./styles.scss";
import React, { useEffect, useState } from "react";
import Section from "shared/components/section";
import Button from "shared/components/bButton";
import Payment from "./components/payment";
import { getQueryParam, openInNewTab } from "shared/utils/helper";
import { getPaymentSecret } from "modules/website/redux/operators";
import Spinner from "shared/components/spinner";
import { getUpgradingPrice } from "shared/infra/redux/global/global";
import { useSelector } from "react-redux";

const Upgrade: React.FC = () => {
	const upgradingPrice = useSelector(getUpgradingPrice)
	const tier: any = getQueryParam('package');
	const websiteId: any = getQueryParam('website_id');
	const websiteSlug: any = getQueryParam('website_slug');
	const [stripeClientSecret, setStripeClientSecret] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [amountCharged, setAmountCharged] = useState<any>(null);
	const [paymentCompletionStatus, setPaymentCompletionStatus] = useState(null);

	const [guessWhoGameIsCreating, setGuessWhoGameIsCreating] = useState(false)

	const handleSecretGeneration = async () => {
		setIsLoading(true)
		const response = await getPaymentSecret(websiteId, { amount: amountCharged, description: `Upgrading package to ${tier}`, calculateFee: false })

		setIsLoading(false)
		setStripeClientSecret(response?.data?.client_secret)
	}


	useEffect(() => {
		if (!!amountCharged && !!websiteId && !stripeClientSecret) handleSecretGeneration()
	}, [amountCharged, websiteId])


	useEffect(() => {
		if (!!upgradingPrice) {
			setAmountCharged(upgradingPrice?.stripe?.amount);
		}

	}, [upgradingPrice]);

	return (
		<>
			<Section
				backgroundColor="transparent"
				styles={{ border: "5px solid #FCE4F9", marginTop: "2%", minHeight: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				{
					isLoading ?
						<Spinner section />
						:

						<div className="upgradeContainer">
							{!!paymentCompletionStatus ?
								<>
								<p className="heading">
									{paymentCompletionStatus === "success" ? 
										"Your villie has been successfully upgraded! Hooray!" 
										: "Your payment is processing. As soon as it's done, your villie will be upgraded!"}
								</p>
								</>
								:
								<>
									<p className="heading">
										{
											guessWhoGameIsCreating?
											"We're upgrading your experience! Please wait a few moments and avoid closing the tab"
											:
											`Upgrade your Villie page with a one-time payment of ${upgradingPrice?.local?.amount} (${upgradingPrice?.local?.currency}) to unlock all features and perks`
										}
										
									</p>

									{!!stripeClientSecret && <div className="paymentBox">
										<Payment
											amountCharged={amountCharged}
											stripeClientSecret={stripeClientSecret}
											data={{ slug: websiteSlug, id: websiteId, package: tier }}
											handlePaymentComplete={(paymentStatus: any) => setPaymentCompletionStatus(paymentStatus)}
											setGuessWhoGameIsCreating={setGuessWhoGameIsCreating}
											guessWhoGameIsCreating={guessWhoGameIsCreating}

										/>
									</div>}


									<Button
										type={"base4"}
										classes={["learnMoreBtn"]}
										text={`LEARN MORE`}
										action={() => { openInNewTab('https://www.villie.com/features') }}
									/>
								</>}
						</div>
				}

			</Section>
		</>
	);
};
export default Upgrade;
