import React, { useEffect, useState } from "react";
import "./styles.scss";
import Modal from "shared/components/modal";
import BButton from "shared/components/bButton";
import { useNavigate } from "react-router-dom";
import { getLocalPricingByCode } from "shared/utils/helper";
import { PostHog } from "shared/services/posthog";
import Spinner from "shared/components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { getSourceOfUpgradeModal, setSourceOfUpgradeModal, setUpgradingPrice } from "shared/infra/redux/global/global";

interface props {
	show: boolean;
	close: any;
	data: any;
}

const UpgradeTierModal: React.FC<props> = ({ show, close, data }) => {
    const sourceOfUpgradeModal = useSelector(getSourceOfUpgradeModal)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [price, setPrice] = useState<any>(null);

    useEffect(() => {
      const fetchPricing = async () => {
        const result = await getLocalPricingByCode(data?.country);
        setPrice(result);
        dispatch(setUpgradingPrice({price: result}))
      };
  
      fetchPricing();
    }, []);

    const handleClose = () => {
        close()
        dispatch(setSourceOfUpgradeModal({sourceOfUpgradeModal: null}))
    }

	return (
		<>
			<Modal show={show} close={handleClose} head={false} classes={["upgradeTierModal"]}>
				<div className="upgradeTierModalContainer">
                    <p className="title">Update Your Plan</p>
                        {sourceOfUpgradeModal === "gifts" && 
                        <p className="subheadline">
                            Villie is free for parents, thanks to our amazing brand partners! 💜 We feature them here so your loved ones can gift you the essentials. Prefer a cleaner look? Upgrade once to remove this section—plus unlock extra perks!
                        </p>
                    }
                    <div className="plansBox">
                        <div className="tier">
                            <div className="tierBadge">BASIC PLAN</div>
                            <p className="tierPrice">Free</p>
                            <p className="tierCopy">Villie will always be free.</p>
                            <br />
                            {
                                ["Up to 30 Villagers", "Accepts Funds and Donations", "Gender Reveal Game", "8 Color Themes", "Customer Support"].map((item, index) => (
                                    <div className="tierDetails" key={index}>
                                        <img className="icon" src="../assets/newIcons/purple-filled-heart.svg" alt="heart" />
                                        <p className="copy">{item}</p>
                                    </div>
                                ))
                            }
                            
                        </div>

                        <div className="tier" style={{background: "#9745DA"}}>
                            <div className="tierBadge">UPGRADED PLAN</div>
                                {
                                    !price ?
                                    <Spinner />
                                    :
                                    <p className="tierPrice" style={{color: "#FFF"}}>
                                        {price?.local?.amount} <small>{price?.local?.currency}</small>
                                    </p>
                                }
                            
                            <br />
                            {
                                [   "Up to 500 Villagers", 
                                    "Accepts Funds and Donations", 
                                    "Access to All Games", 
                                    "Premium Themes", 
                                    "Customer Support",
                                    "Ad-Free Experience",
                                    "Password Protection",
                                    "Extra Page Customization",
                                    "Exclusive Discounts",
                                    "Villie Freebie Box"
                                ].map((item, index) => (
                                    <div className="tierDetails" key={index}>
                                        <img className="icon" src="../assets/newIcons/purple-filled-heart.svg" alt="heart" />
                                        <p className="copy" style={{color: "#FFF", fontSize: ".85rem"}}>{item}</p>
                                    </div>
                                ))
                            }
                            <br />
                            <BButton 
                                text="Upgrade your plan now" 
                                type="base4" 
                                action={() => {
                                    close();
                                    PostHog.track("upgrade_start", {
                                        website_id: data?.id,
                                        website_slug: data?.slug,
                                        country: data?.country
                                    })
                                    navigate(`/upgrade?package=tier1&website_slug=${data?.slug}&website_id=${data?.id}`)
                                }}
                            />
                        </div>

                    </div>
                </div>
			</Modal>
		</>
	);
};

export default UpgradeTierModal;
