import './styles.scss';
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/bButton';
import DonationDropdown from 'shared/components/donationDropdown';
import classNames from 'classnames';
import DecisionButtons from 'shared/composedComponents/decisionButtons';
import { updateWebsite } from 'modules/website/redux/operators';
import Spinner from 'shared/components/spinner';
import Notification from 'shared/components/notification';
import { capitalize, showFundContributions } from 'shared/utils/helper';
import { Mixpanel } from 'shared/services/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
import { getWebsite, setWebsite } from 'shared/infra/redux/global/global';
import Url from 'shared/components/url';
import DeleteRegistry from '../deleteRegistry';
import AddRegistryForm from '../addRegistryForm';
import EditRegistryModal from '../../modals/editRegistryModal';
import { GiftOutlined } from '@ant-design/icons';
import { PostHog } from 'shared/services/posthog';
import { useSearchParams } from 'react-router-dom';

interface props {
    setClosingCollapse: any;
}

const BabyFundsForm: React.FC<props> = ({ setClosingCollapse }) => {
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();
    const data: any = useSelector(getWebsite);
    const user = useSelector(getUser);
    // const [receiveFund, setReceivedFunds] = useState(data?.receive_fund);
    const [receiveFund, setReceivedFunds] = useState(
        data?.receive_fund === 1 || data?.receive_fund === true || data?.receive_fund === 'true'
    );
    const [fundType, setFundType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isAddRegistryVisible, setIsAddRegistryVisible] = useState(false);
    const [isDeleteRegistryConfirmationVisible, setIsDeleteRegistryConfirmationVisible] =
        useState(false);
    const [registryOperationData, setRegistryOperationData] = useState(null);
    const [isEditRegistryModalVisible, setIsEditRegistryModalVisible] = useState(false);

    const handleFunds = async () => {
        const payload: any = {
            receive_fund: receiveFund,
        };

        if (receiveFund && fundType !== 'Select Your Fund') {
            payload['fund_type'] = fundType;
        }

        setIsLoading(true);
        const response = await updateWebsite(data.id, payload);
        setIsLoading(false);

        if (response?.status === 'success') {
            if (payload?.receive_fund) {
                Mixpanel.track('PARENT_adds_a_fund', {
                    $name: user?.name,
                    $email: user?.email,
                });
                PostHog.track("add_fund", {})
            }

            dispatch(setWebsite({ website: { ...data, ...payload } }));
            setClosingCollapse(true);
            Notification('success', 'Updated successfully.');
        } else {
            setIsLoading(false);
            Notification('error', 'There is an error. Try again later.');
        }
    };

    useEffect(() => {
        if (searchParams.has('tab') && searchParams.get('tab') === 'registry') {
            const element = document.getElementById('registry');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [searchParams.has('tab')])

    return (
        <>
            <div className="babyFundsFormContainer">
                {
                    showFundContributions(data) &&
                    <>
                        <div className="new-ui-box">
                            <h1>
                                <b>$ Donation</b>
                            </h1>
                            <DecisionButtons
                                label="Do you want to accept donations? You can cash out anytime."
                                actionOnYes={() => setReceivedFunds(true)}
                                actionOnNo={() => setReceivedFunds(false)}
                                decision={receiveFund ? 'Yes' : 'No'}
                            />

                            {receiveFund === true && (
                                <>
                                    <DonationDropdown
                                        funds={[
                                            'Select Your Fund',
                                            'Diaper Fund',
                                            'Baby Fund',
                                            'Education Fund',
                                        ]}
                                        setFundType={(fundType: string) => setFundType(fundType)}
                                        fundType={fundType}
                                    />
                                </>
                            )}
                        </div>
                        <hr />
                    </>
                }
                <div className="registryBoxFunds" id="registry">
                    <p className="label">
                        <GiftOutlined />
                        Baby Registry

                    </p>
                    <p className="text">
                        Add all your registries to your baby page so your villagers can find them easy!
                    </p>
                    <div
                        className={classNames('registryArea', {
                            isRegistryAreaVisible: isAddRegistryVisible,
                        })}
                    >
                        <div
                            className={classNames('topAreaRegistry', {
                                isTopAreaRegistryVisible: isAddRegistryVisible,
                            })}
                        >
                            <p className="headingRegistryArea">Add a Baby Registry</p>
                            <img
                                className="iconRegistryArea"
                                src="../assets/newIcons/plus.svg"
                                onClick={() => setIsAddRegistryVisible(!isAddRegistryVisible)}
                                alt="add-registry-icon"
                            />
                        </div>
                        <div
                            className={classNames('bottomAreaRegistry', {
                                isBottomAreaRegistryVisible: isAddRegistryVisible,
                            })}
                        >
                            <AddRegistryForm
                                data={data}
                                successAction={() => setIsAddRegistryVisible(false)}
                            />
                        </div>
                    </div>
                </div>

                <div className="registryDisplayBox">
                    {data?.registries?.map((registry: any) => (
                        <div className="registry">
                            <img
                                className="deleteBtn"
                                src="../assets/newIcons/delete.svg"
                                alt="delete-icon"
                                onClick={() => {
                                    setRegistryOperationData(registry);
                                    setIsDeleteRegistryConfirmationVisible(true);
                                }}
                            />
                            {registry?.registry_brand !== 'Other' ? (
                                <img
                                    src={`../assets/registries/${capitalize(
                                        registry?.registry_brand
                                    )}.svg`}
                                    alt="registries"
                                    className="img"
                                />
                            ) : (
                                <p>Registry</p>
                            )}

                            <Url
                                text="Edit"
                                action={() => {
                                    setIsEditRegistryModalVisible(true);
                                    setRegistryOperationData(registry);
                                }}
                            />
                        </div>
                    ))}
                </div>
                <Button
                    type={'base4'}
                    // htmlType="submit"
                    classes={['w-100-p', 'm-t-3-p']}
                    text={isLoading ? <Spinner /> : `Save`}
                    action={handleFunds}
                />
            </div>

            <DeleteRegistry
                show={isDeleteRegistryConfirmationVisible}
                close={() => {
                    setRegistryOperationData(null);
                    setIsDeleteRegistryConfirmationVisible(false);
                }}
                registry={registryOperationData}
                data={data}
            />

            <EditRegistryModal
                show={isEditRegistryModalVisible}
                close={() => {
                    setRegistryOperationData(null);
                    setIsEditRegistryModalVisible(false);
                }}
                registry={registryOperationData}
                data={data}
            />
        </>
    );
};

export default BabyFundsForm;
