import "./styles.scss";
import React, { useState } from 'react';
import { Row, Col, Form, Alert } from "antd";
import Modal from 'shared/components/modal';
import Paragraph from "shared/components/paragraph";
import Input from "shared/components/binput";
import Button from "shared/components/bButton";
// import PersonalInfoWidget from "shared/components/personalInfoWidget";
import { addGuest } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import DecisionButtons from "shared/composedComponents/decisionButtons";
import HelperText from "shared/components/helperText";
import { getFirstNameAndLastName } from "shared/utils/helper";
import { Mixpanel } from "shared/services/mixpanel";
import { PostHog } from "shared/services/posthog";
import { getUser } from "modules/user/redux/userSlice";
import { useSelector } from "react-redux";

interface props {
    website?: any
    show: boolean
    close: any
    showSuccess?: boolean
}

const FollowOurVillieModal: React.FC<props> = ({ show, close, website, showSuccess }) => {
    const user = useSelector(getUser);
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const [askForEmail, setAskForEmail] = useState(false)
    const [askForPhone, setAskForPhone] = useState(false)
    const [error, setError] = useState(false)

    const addGuestInfo = async (values: any) => {
        if (!(askForEmail || askForPhone)) {
            return setError(true)
        }
        if (isLoading) return 0

        setIsLoading(true)
        const response = await addGuest(website?.id, values)
        setIsLoading(false)

        if (response?.status === "success") {
            Mixpanel.track(
                "VILLAGER_follow",
                {
                    website_id: website?.id,
                    website_slug: website?.slug
                }
            )
            PostHog.track("villager_follow", {})
            form.resetFields()
            handleClose()
            Notification("success", "Done")
        }
        else {
            Notification("error", response?.message)
        }
    }

    const handleClose = () => {
        setAskForEmail(false)
        setAskForPhone(false)
        form.resetFields()
        close()
    }

    return (
        <>
            <Modal show={show} close={handleClose} head={false} title={``} destroyOnClose>
                <Row className="followOurVillieModalContainer" gutter={[24, 0]}>
                    {/* <Col>
                        <div className="leftSide">
                            <Paragraph classes={["headline"]}>Follow Our Villie</Paragraph>
                            <div className="parentInfoBox">
                                <PersonalInfoWidget website={website}/>
                            </div>
                            <Image className="image"
                                src={website?.photos?.[0]?.img_url || `../assets/placeholders/villie/image${website?.slug?.length % 3}.png`}
                                alt="logo" preview={false}
                            />
                        </div>
                    </Col> */}
                    <Col className="rightSide">
                        {
                            (!!user && user?.id === website?.user_id) ?
                            <>
                                <Paragraph classes={["subheadline"]}>
                                    Villagers can follow your baby page to stay in the loop! They’ll get updates through email or text—whatever they prefer—so they never miss a milestone, special moment, or chance to support you.
                                </Paragraph>
                                <Button
                                    action={handleClose}
                                    type="theme"
                                    text={"Done"}
                                    classes={["submitBtn"]}
                                />
                            </>
                            :
                        <>
                            {showSuccess && <Alert message={"Success"} type="info" style={{ marginBottom: "20px" }} />}
                            <Paragraph classes={["headline"]}>Follow Our Villie</Paragraph>
                            <Form
                                name="followOurVillieForm"
                                onFinish={addGuestInfo}
                                autoComplete="off"
                                form={form}
                                layout="vertical"
                            >
                                <DecisionButtons
                                    label="Are you in the USA?"
                                    actionOnYes={() => {
                                        setAskForPhone(true);
                                        setAskForEmail(false);
                                        setError(false)
                                    }}
                                    actionOnNo={() => {
                                        setAskForEmail(true);
                                        setAskForPhone(false);
                                        setError(false)
                                    }}
                                />
                                {error && <HelperText text={"This is required"} type="error" styles={{ marginTop: "-.5rem", marginBottom: ".5rem" }} />}
                                {
                                    askForPhone &&
                                    <Input
                                        label="Phone number"
                                        name={"phone"}
                                        helper={"We will text you updates on this Villie. We will not spam you or sell your details."}
                                        minLength={10}
                                        required
                                    />
                                }
                                {
                                    askForEmail &&
                                    <Input
                                        label="Email"
                                        name={"email"}
                                        required
                                    />
                                }

                                <Input
                                    label="Name"
                                    name={"name"}
                                    required
                                />

                                <Input
                                    label={`How do you know ${getFirstNameAndLastName(website?.village_owner)?.first_name}?`}
                                    name={"relation_with_mom"}
                                    placeholder="ex: aunt, cousin"
                                    required
                                />

                                <Button
                                    htmlType="submit"
                                    type="theme"
                                    text={"Join This Villie"}
                                    classes={["submitBtn"]}
                                />
                            </Form>
                        </>
                        }

                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default FollowOurVillieModal;