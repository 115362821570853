import moment from "moment";
import { app } from "shared/infra/config";
import Compressor from "compressorjs";
import { btnThemes } from "./themeColors";
import { checklist as checklistData } from "./constants";
import { PostHog } from "shared/services/posthog";
import countryToCurrency from "country-to-currency";

export const showFundContributions = (data :any) => {

	if(!data) return false

	const { country, receive_fund } = data

	if (["US", "United States"].includes(country) && !!receive_fund) {
		return true;
	} else {
		return false;
	}
};

export const showGiftCards = (data: any) => {

	if(!data) return false

	const { country, package: subscription, free_trial_started_at,} = data

	if (
		["US", "United States"].includes(country) &&
		subscription !== "tier1" &&
		!isFreeTrialValid(free_trial_started_at)
	) {
		return true;
	} else {
		return false;
	}
};

export const showAds = (data: any) => {

	if(!data) return false

	const { country } = data

	if (["US", "United States"].includes(country)) {
		return false;
	} else {
		return true;
	}
};

export const raiseFunds = (data: any) => {

	if(!data) return false

	const { country } = data

	if (["US", "United States"].includes(country)) {
		return true;
	} else {
		return false;
	}
};

// export const getLocalPricingByCode = async (countryCode: any) => {

//     const upperCode = countryCode.toUpperCase();

//     const currencyCode = countryToCurrency[upperCode as keyof typeof countryToCurrency];

//     if (!currencyCode) {
//         return { currency: 'USD', amount: 39.99 };
//     }

//     // Define pricing tiers
//     const highTier = ['USD', 'CAD'];
//     const midTier = [
//         'EUR', 'GBP', 'CHF', 'SEK', 'NOK', 'DKK', 'PLN', 'CZK', 'RON', 'HUF', 'UAH', 'RUB', 'HRK', 'BGN'
//     ];

//     let priceUSD = 2.99;
//     if (highTier.includes(currencyCode)) {
//         priceUSD = 39.99;
//     } else if (midTier.includes(currencyCode)) {
//         priceUSD = 10.99;
//     }

//     // Fetch rate from CurrencyFreaks
//     const url = `https://api.currencyfreaks.com/v2.0/rates/latest?apikey=${API_KEY}&symbols=${currencyCode}&base=USD`;
//     const response = await fetch(url);
//     const data = await response.json();

//     const rate = parseFloat(data.rates[currencyCode]);

//     if (!rate || isNaN(rate)) {
//         return { currency: currencyCode, amount: priceUSD }; // fallback to USD value if rate is not found
//     }

//     const localAmount = parseFloat((priceUSD * rate).toFixed(2));
//     return { currency: currencyCode, amount: localAmount };
// }

export const getLocalPricingByCode = async (
	countryCode: string
): Promise<{
	local: { currency: string; amount: number };
	stripe: { currency: string; amount: number };
}> => {
	const API_KEY = "d601066a1cd64dde9287cb236c7777f5";

	if (!countryCode) {
		return {
		  local: { currency: 'USD', amount: 39.99 },
		  stripe: { currency: 'USD', amount: 39.99 },
		};
	  }

	const upperCode = countryCode.toUpperCase();
  const currencyCode = countryToCurrency[upperCode as keyof typeof countryToCurrency];

  if (!currencyCode) {
    return {
      local: { currency: 'USD', amount: 39.99 },
      stripe: { currency: 'USD', amount: 39.99 },
    };
  }

  // Define pricing tiers
  const highTier = ['USD', 'CAD'];
  const midTier = [
    'DKK', 'EUR', 'ISK', 'NOK', 'SEK', 'GBP',
    'CHF', 'CZK', 'HUF', 'PLN',
    'BYN', 'MDL', 'RUB', 'UAH',
    'ALL', 'BAM', 'BGN', 'MKD', 'RON', 'RSD'
  ]

  let priceUSD = 2.99;
  let tier: 'high' | 'mid' | 'low' = 'low';

  if (highTier.includes(currencyCode)) {
    priceUSD = 39.99;
    tier = 'high';
  } else if (midTier.includes(currencyCode)) {
    priceUSD = 10.99;
    tier = 'mid';
  }

  // Get exchange rate
  const url = `https://api.currencyfreaks.com/v2.0/rates/latest?apikey=${API_KEY}&symbols=${currencyCode}&base=USD`;
  const response = await fetch(url);
  const data = await response.json();

  const rate = parseFloat(data.rates[currencyCode]);

  if (!rate || isNaN(rate)) {
    return {
      local: { currency: currencyCode, amount: priceUSD },
      stripe: { currency: 'USD', amount: priceUSD },
    };
  }

  let localAmount = priceUSD * rate;

  // Round down for 3rd world countries (tier = 'low')
  if (tier === 'low') {
    localAmount = Math.floor(localAmount);
  } else {
    localAmount = parseFloat(localAmount.toFixed(2));
  }

  // Stripe logic: USD only, minus $0.15 for non-US
  const stripeAmount = upperCode === 'US'
    ? priceUSD
    : parseFloat((priceUSD - 0.15).toFixed(2));

  return {
    local: { currency: currencyCode, amount: localAmount },
    stripe: { currency: 'USD', amount: stripeAmount },
  };
};

export const stopEventPropagation = (e: React.SyntheticEvent<EventTarget>) => {
	e.stopPropagation();
	e.nativeEvent.stopPropagation();
};

export const getMomentObj = (date: any) => {
	return moment(date);
};

export const extractDigitsFromString = (str: string) => {
	// Use a regular expression to match all digits
	const digits = str.match(/\d/g);

	// If digits are found, join them into a string; otherwise, return an empty string
	return digits ? digits.join("") : null;
};

export const formatedDate = (date: any, format: any = "YYYY-MM-DD") => {
	const dateWithoutTime = moment.utc(date).startOf("day");
	return dateWithoutTime.format(format);
};

export const calculateServiceFeeAndActualAmountFromFinalCost = (
	finalCost: any
) => {
	if (!finalCost) return 0;

	const costWithServiceFee = parseFloat(finalCost);
	const serviceFee = (costWithServiceFee - costWithServiceFee / 1.1).toFixed(
		2
	);
	let amount: any = costWithServiceFee - parseFloat(serviceFee);
	if (parseFloat(amount) < 6) {
		amount -= 0.1;
	}
	return { serviceFee, actualAmount: parseFloat(amount.toFixed(2)) };
};

export const calculateServiceFeeAndActualAmount = (finalCost: any) => {
	if (!finalCost) return { serviceFee: "0.00", actualAmount: 0 };

	const costWithServiceFee = parseFloat(finalCost);
	const serviceFee = (costWithServiceFee - costWithServiceFee / 1.1).toFixed(
		2
	);
	let amount: any = costWithServiceFee - parseFloat(serviceFee);
	if (parseFloat(amount) < 6) {
		amount -= 0.1;
	}
	return { serviceFee, actualAmount: parseFloat(amount.toFixed(2)) };
};

export const DATETIME = new Date().toISOString().slice(0, 10);

export const formattedDateIncreasedByOneDay = (
	date: any,
	format: any = "YYYY-MM-DD"
) => {
	const dateWithoutTime = moment.utc(date).startOf("day").add(1, "day");
	return dateWithoutTime.format(format);
};

export const getCurrentPath = () => {
	return window.location.href.replace(`${app.APP_PUBLIC_URL}`, "");
};

export const calculateDaysPassed = (startDate: any) => {
	const currentDate = moment();
	const startDateMoment = moment(startDate);

	const daysPassed = currentDate.diff(startDateMoment, "days");

	return daysPassed;
};

export const blobToBase64 = (blob: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const getQueryParams = () => {
	return new URLSearchParams(window.location.search);
};

export const getQueryParam = (param: string) => {
	return new URLSearchParams(window.location.search).get(param);
};

export const getBackgroundColorWithOpacity = (color: any, opacity: any) => {
	const hex = color.replace("#", "");
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const ganerateRandom = (min: any, max: any) => {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const removeBeforeHttp = (str: string) => {
	var httpIndex = str.indexOf("http");
	if (httpIndex >= 0) {
		return str.slice(httpIndex);
	} else {
		return str;
	}
};

export const getGender = (gameName: string) => {
	// 0 boy, 1 girl
	return gameName?.length % 2;
};

export const calculateVoterPercentage = (
	boysVotes: any,
	girlVotes: any,
	gender: any
) => {
	const totalVotes = parseInt(boysVotes) + parseInt(girlVotes);

	return gender === "boy"
		? (parseInt(boysVotes) / totalVotes) * 100
		: (parseInt(girlVotes) / totalVotes) * 100;
};

export const convertBlobToBase64 = async (images: any) => {
	if (!images) return [];

	return await Promise.all(
		images?.map(async (i: any) => {
			if (!!i?.blob) {
				const img: any = await blobToBase64(i?.blob);
				return {
					delete_flag: null,
					image: img.replace("application/zip", "image/jpeg"),
					type: "jpeg",
				};
			} else {
				return i;
			}
		})
	);
};

export const newConvertBlobToBase64 = async (images: any) => {
	if (!images) return [];

	return await Promise.all(
		images?.map(async (image: any) => {
			const img: any = await blobToBase64(image?.blob);
			return {
				image: img.replace("application/zip", "image/jpeg"),
				type: "jpeg",
			};
		})
	);
};

export const getFirstNameLastName = (fullName: any) => {
	const names = fullName?.trim()?.split(" ");
	const firstName = names[0];
	const lastName = names?.slice(1)?.join(" ");

	return {
		first_name: firstName,
		last_name: lastName,
	};
};

export const getFirstNameAndLastName = (fullName: any) => {
	let first_name = "";
	let last_name = "";

	if (!fullName) {
		return { first_name, last_name };
	}

	const names = fullName?.trim()?.split(" ");
	first_name = names[0];
	last_name = names?.slice(1)?.join(" ");

	return { first_name, last_name };
};

interface ColorMapping {
	[hex: string]: string;
}

export const getColorName = (hex: string) => {
	const colors: ColorMapping = {
		"#5363FF": "Blue",
		"#D9ABFE": "Purple",
		"#6EAE1C": "Green",
	};

	return colors[hex];
};

export const getBackgroundColor = (hex: string) => {
	const colors: ColorMapping = {
		"#5363FF": "#eeefff",
		"#D9ABFE": "#fcf7ff",
		"#6EAE1C": "#f1f7e7",
	};

	return colors[hex];
};

export const getColorWithOpacity = (hex: string, opacity: number) => {
	const colors: ColorMapping = {
		"#5363FF": `rgba(82, 99, 255, ${opacity})`,
		"#D9ABFE": `rgba(217, 171, 254, ${opacity})`,
		"#6EAE1C": `rgba(110, 174, 28, ${opacity})`,
	};

	return colors[hex];
};

export const setCSSVariable = (name: string, value: string) => {
	document.documentElement.style.setProperty(name, value);
};

export const calculateServiceFee = (amount: string) => {
	const fee = (parseFloat(amount) * 0.1)?.toFixed(2);

	return fee === "NaN" ? 0 : parseFloat(fee);
};

export const capitalize = (str: string) => {
	return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const createImage = (url: any) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

export function getRadianAngle(degreeValue: any) {
	return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: any, height: any, rotation: any) {
	const rotRad = getRadianAngle(rotation);

	return {
		width:
			Math.abs(Math.cos(rotRad) * width) +
			Math.abs(Math.sin(rotRad) * height),
		height:
			Math.abs(Math.sin(rotRad) * width) +
			Math.abs(Math.cos(rotRad) * height),
	};
}

export default async function getCroppedImg(
	imageSrc: any,
	pixelCrop: any,
	rotation = 0,
	flip = { horizontal: false, vertical: false }
) {
	const image: any = await createImage(imageSrc);
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	if (!ctx) {
		return null;
	}

	const rotRad = getRadianAngle(rotation);

	// calculate bounding box of the rotated image
	const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
		image.width,
		image.height,
		rotation
	);

	// set canvas size to match the bounding box
	canvas.width = bBoxWidth;
	canvas.height = bBoxHeight;

	// translate canvas context to a central location to allow rotating and flipping around the center
	ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
	ctx.rotate(rotRad);
	ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
	ctx.translate(-image.width / 2, -image.height / 2);

	// draw rotated image
	ctx.drawImage(image, 0, 0);

	const croppedCanvas = document.createElement("canvas");

	const croppedCtx = croppedCanvas.getContext("2d");

	if (!croppedCtx) {
		return null;
	}

	// Set the size of the cropped canvas
	croppedCanvas.width = pixelCrop.width;
	croppedCanvas.height = pixelCrop.height;

	// Draw the cropped image onto the new canvas
	croppedCtx.drawImage(
		canvas,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		pixelCrop.width,
		pixelCrop.height
	);

	return new Promise((resolve, _) => {
		croppedCanvas.toBlob((file: any) => {
			resolve({ blob: file, img_url: URL.createObjectURL(file) });
		}, "image/jpeg");
	});
}

export const compressImage = (file: any) => {
	return new Promise((resolve, reject) => {
		new Compressor(file, {
			quality: 0.7,
			success(result) {
				const compressedBlob = new Blob([result], { type: file.type });
				resolve(compressedBlob);
			},
			error(err) {
				console.error("Compression error:", err);
				reject(err);
			},
		});
	});
};

export const extractDate = (date: string) =>
	!!date ? new Date(date).toISOString().slice(0, 10) : null;

export const formatDate = (
	date: string,
	format = "MMM d, yyyy",
	timeZone = "UTC"
) => {
	const dateObj = new Date(date);

	const formatMap: any = {
		"MMM d, yyyy": {
			year: "numeric",
			month: "short",
			day: "numeric",
			timeZone,
		}, // e.g., May 31, 2024
		"yyyy-MM-dd": {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			timeZone,
		}, // e.g., 2024-05-31
		"d MMMM yyyy": {
			year: "numeric",
			month: "long",
			day: "numeric",
			timeZone,
		}, // e.g., 31 May 2024
		"EEEE, MMM d, yyyy": {
			year: "numeric",
			month: "short",
			day: "numeric",
			weekday: "long",
			timeZone,
		}, // e.g., Friday, May 31, 2024
		"MM/dd/yyyy": {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			timeZone,
		}, // e.g., 05/31/2024
		"MMM d": { month: "short", day: "numeric", timeZone }, // e.g., May 31
		"EEEE, MMMM dd": {
			weekday: "long",
			month: "long",
			day: "2-digit",
			timeZone,
		}, // e.g., Sunday, March 03
		"dddd, MMMM D, YYYY": {
			weekday: "long",
			month: "long",
			day: "numeric",
			year: "numeric",
			timeZone,
		}, // e.g., Sunday, March 3, 2024
		"EEEE, MM/dd": {
			weekday: "long",
			month: "2-digit",
			day: "2-digit",
			timeZone,
		}, // e.g., Tuesday, 11/09
	};

	const options = formatMap[format] || {};

	return new Intl.DateTimeFormat("en-US", options).format(dateObj);
};

export const chunkArray = (array: any[], chunkSize: number) => {
    const chunkedArray: any = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
}

export const openInNewTab = (url: string) => {
	window.open(url, "_blank", "noopener,noreferrer");
};

export const updateTheme = (color: string) => {
	setCSSVariable("--theme-color", color);

	const btnColor = btnThemes[color];
	if (
		btnColor === "#03045E" ||
		btnColor === "#FB6F92" ||
		btnColor === "#D4A373" ||
		btnColor === "#D5BDAF"
	) {
		setCSSVariable("--btn-color", "#fff");
		if (btnColor === "#D4A373") {
			setCSSVariable("--text-color", "#6B705C");
			setCSSVariable("--preview-span-color", "#6B705C");
		} else {
			setCSSVariable("--text-color", "#463649");
			setCSSVariable("--preview-span-color", "#463649");
		}
	} else {
		setCSSVariable("--btn-color", "#463649");
		setCSSVariable("--text-color", "#463649");
		setCSSVariable("--preview-span-color", "#463649");
	}
	setCSSVariable("--btn-background", btnColor);
};

export const getMainPhoto = (photos: any) => {
	let haveMainPhoto = null;
	photos?.map((p: any) => {
		if (p?.is_main_image) {
			haveMainPhoto = p;
		}
	});

	return !!haveMainPhoto
		? haveMainPhoto
		: photos?.length > 0
		? photos[0]
		: [];
};

export const calculateTrimesterAndMop = (dueDate: any) => {
	dueDate = new Date(dueDate);

	const currentDate: any = new Date();
	const timeDifference = dueDate - currentDate;

	if (timeDifference > 0) {
		const daysRemaining = Math.floor(
			timeDifference / (1000 * 60 * 60 * 24)
		);
		const monthsPregnant = Math.floor((280 - daysRemaining) / 30);

		if (daysRemaining >= 0 && daysRemaining <= 84) {
			return {
				mop: `${monthsPregnant} months pregnent`,
				trimester: "Third trimester",
			};
		} else if (daysRemaining > 84 && daysRemaining <= 182) {
			return {
				mop: `${monthsPregnant} months pregnent`,
				trimester: "Second trimester",
			};
		} else if (daysRemaining > 182 && daysRemaining <= 280) {
			return {
				mop: `${monthsPregnant} months pregnent`,
				trimester: "First trimester",
			};
		} else {
			return {
				mop: "Due date is too far in the future",
				trimester: "Due date is too far in the future",
			};
		}
	} else {
		const timeDifference = currentDate - dueDate;
		const daysOld = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
		const monthsOld = Math.floor(daysOld / 30);
		return {
			mop: `${monthsOld} months old`,
			trimester: "Due date has passed",
		};
	}
};

export const getBtnBackgroundColor = (bg: string, current_bg: any) => {
	let backgroundColor = undefined;
	let borderColor = undefined;
	let color = undefined;

	if (bg === "#FF8FAB" || bg === "#00B4D8") {
		if (current_bg === "#FFB3C6" || current_bg === "#FF8FAB") {
			backgroundColor = "#FFE5EC";
			borderColor = "#FFE5EC";
			color = "#463649";
		}
		if (current_bg === "#0077B6") {
			backgroundColor = "#C8EBF2";
			borderColor = "#C8EBF2";
			color = "#463649";
		}
	}
	return {
		backgroundColor,
		border: `3px solid ${borderColor}`,
		color,
	};
};

export const getTextColor = (bg: string, current_bg: any) => {
	let color = undefined;

	if (bg === "#FF8FAB" || bg === "#00B4D8") {
		if (current_bg === "#FF8FAB") {
			color = "#fff";
		}
		if (current_bg === "#0077B6") {
			color = "#fff";
		}
	}
	return { color };
};

export const getPackagePrice = (packageName: string) => {
	switch (packageName) {
		case "tier0":
			return 0;
		case "tier1":
			return 39.99;
		case "tier2":
			return 20;
	}
};

export const updateChecklistByInfoAndStringify = (
	currentChecklist: any,
	info: string
) => {
	// Initialize checklist if currentChecklist is null
	let checklist = [];
	let checklist_completed_at = null;

	// If there is an existing checklist, use it
	if (currentChecklist) {
		({ checklist, checklist_completed_at } = currentChecklist);
	} else {
		// Initialize a new checklist if it doesn't exist yet
		checklist = checklistData;
	}

	// Update the checklist by searching for the task with the given 'info'
	checklist = checklist.map((task: any) => {
		if (task.info === info) {
			return {
				...task,
				completed: true,
				completed_at: new Date().toISOString(), // Mark it as completed with the current timestamp
			};
		}
		return task;
	});

	// Check if all tasks are now completed
	const allCompleted = checklist.every((task: any) => task.completed);

	// If all tasks are completed, set the checklist_completed_at
	if (allCompleted && !checklist_completed_at) {
		checklist_completed_at = new Date().toISOString();
		PostHog.track("gems_completed", {});
	}

	// Prepare the updated object
	const updatedChecklistObject = {
		checklist,
		checklist_completed_at,
	};

	// Return the updated checklist object as a JSON string
	return JSON.stringify(updatedChecklistObject);
};

export const isChecklistTaskCompleted = (
	checklist: any,
	task: string
): boolean => {
	return (
		checklist?.setting_value?.checklist?.find((t: any) => t.info === task)
			?.completed || false
	);
};

export const isFreeTrialValid = (free_trial_started_at: any) => {
	// Convert the input string to a Date object
	const startDate = new Date(free_trial_started_at);

	// Add 14 days to the start date
	const trialEndDate = new Date(startDate);
	trialEndDate.setDate(startDate.getDate() + 7);

	// Get the current date
	const currentDate = new Date();

	// Check if the current date is before or on the trial end date
	return currentDate <= trialEndDate;
};

export const getDueDataOrDobLabel = (due_date_or_dob: any, created_at: any) => {
	const dueOrDob = new Date(due_date_or_dob);
	const createdAt = new Date(created_at);
	const now = new Date();

	if (now > dueOrDob) {
		return "Birthday";
	} else if (dueOrDob > createdAt) {
		return "Due Date";
	} else {
		return "Birthdate";
	}
};

// export const getSettingValue = (settings: any[], settingKey: string) => {
//     const setting = settings.find(item => item.setting_key === settingKey);

//     return setting ? setting.setting_value : null;
// }

export const getSettingValue = (settings: any[], settingKey: string) => {
	if (!Array.isArray(settings)) {
		return null;
	}
	const setting = settings.find((item) => item.setting_key === settingKey);
	return setting ? setting.setting_value : null;
};

export const updateSettingsArray = (settings: any[], settingObject: any) => {
	const clonedSettings = [...settings];

	const existingIndex = clonedSettings.findIndex(
		(item) => item.setting_key === settingObject.setting_key
	);

	if (existingIndex !== -1) {
		const existingObject = clonedSettings[existingIndex];
		if (JSON.stringify(existingObject) === JSON.stringify(settingObject)) {
			return clonedSettings;
		} else {
			clonedSettings[existingIndex] = settingObject;
		}
	} else {
		clonedSettings.push(settingObject);
	}

	return clonedSettings;
};

export const downloadCSV = (data: any, fileName: string) => {
	// Convert JSON to CSV
	const headers = Object.keys(data[0]);
	const rows = data.map((obj: any) =>
		headers.map((header) => (obj[header] !== null ? obj[header] : ""))
	);

	const csvContent = [
		headers.join(","), // Add header row
		...rows.map((row: any) => row.join(",")), // Add data rows
	].join("\n");

	// Create a Blob and download
	const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
	const url = URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = url;
	link.download = `${fileName}.csv`;
	link.click();
	URL.revokeObjectURL(url);
};
