import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialGlobalState from './states';
import type { GlobalState } from "./states"
import { RootState } from '../reducers';

export const gobalSlice = createSlice({
    name: 'global',
    initialState: initialGlobalState,
    reducers: {
        setIsAddNotesModalVisible:(state: GlobalState, action: PayloadAction<{ isAddNotesModalVisible: any }>) => {
            state.isAddNotesModalVisible = action.payload.isAddNotesModalVisible;
        },
        setWebsite:(state: GlobalState, action: PayloadAction<{ website: any }>) => {
            state.website = action.payload.website;
        },
        setWebsiteSettings:(state: GlobalState, action: PayloadAction<{ websiteSetting: any }>) => {
            state.websiteSetting = action.payload.websiteSetting;
        },
        setWebsiteSettingsRefresh:(state: GlobalState, action: PayloadAction<{ websiteSettingsRefresh: any }>) => {
            state.websiteSettingsRefresh = action.payload.websiteSettingsRefresh;
        },
        setWebsiteRefresh:(state: GlobalState, action: PayloadAction<{ refresh: any }>) => {
            state.refresh = action.payload.refresh;
        },
        setUpgradeModalVisible:(state: GlobalState, action: PayloadAction<{ isUpdgradeModalVisible: any }>) => {
            state.isUpdgradeModalVisible = action.payload.isUpdgradeModalVisible;
        },
        setSourceOfUpgradeModal:(state: GlobalState, action: PayloadAction<{ sourceOfUpgradeModal: any }>) => {
            state.sourceOfUpgradeModal = action.payload.sourceOfUpgradeModal;
        },
        setGuessWhoGame:(state: GlobalState, action: PayloadAction<{ guessWhoGame: any }>) => {
            state.guessWhoGame = action.payload.guessWhoGame;
        },
        setUpgradingPrice:(state: GlobalState, action: PayloadAction<{ price: any }>) => {
            state.price = action.payload.price;
        },
        resetGlobalState: (state: GlobalState) => {
            state.isAddNotesModalVisible = false
            state.website = null
            state.isUpdgradeModalVisible = false
            state.guessWhoGame = null
            state.sourceOfUpgradeModal = null
        },
    },
});

export const getIsAddNotesModalVisible = (state: RootState) => state.global.isAddNotesModalVisible;
export const getWebsite = (state: RootState) => state.global.website;
export const getWebsiteSettings = (state: RootState) => state.global.websiteSetting;
export const getWebsiteRefresh = (state: RootState) => state.global.refresh;
export const getWebsiteSettingsRefresh = (state: RootState) => state.global.websiteSettingsRefresh;
export const getUpgradeModalVisible = (state: RootState) => state.global.isUpdgradeModalVisible;
export const getGuessWho = (state: RootState) => state.global.guessWhoGame;
export const getUpgradingPrice = (state: RootState) => state.global.price;
export const getSourceOfUpgradeModal = (state: RootState) => state.global.sourceOfUpgradeModal;

export const { setWebsite, setWebsiteRefresh, setIsAddNotesModalVisible, setWebsiteSettings, setWebsiteSettingsRefresh, setUpgradeModalVisible, setSourceOfUpgradeModal, setGuessWhoGame, resetGlobalState, setUpgradingPrice } = gobalSlice.actions;

export default gobalSlice.reducer;